import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Head from "../components/head";
import Img from "gatsby-image";

const PostNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;
`;

const PostContent = styled.div`
  padding: 5px;
  flex: 1 0 auto;
  width: 960px;
  margin: auto;
  max-width: 90vw;
  overflow-y: scroll;
`;

const FeaturedImage = styled.div`
  width: 100vw;
  z-index: -1;
  height: 5rem;
`;

const SImg = styled(Img)`
  transform: translateY(-50%);
`;

const PostBody = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.3rem;
  padding: 1rem;

  img.gatsby-resp-image-image {
    box-shadow: none;
  }
`;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 1500) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`;

const BlogPost = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const post = data.markdownRemark;

  return (
    <Layout>
      <Head title={post.frontmatter.title} />
      {post.frontmatter.featuredImage && (
        <FeaturedImage>
          <SImg sizes={post.frontmatter.featuredImage.childImageSharp.sizes} />
        </FeaturedImage>
      )}
      <PostBody>
        <h1>{post.frontmatter.title}</h1>

        <PostContent dangerouslySetInnerHTML={{ __html: post.html }} />
        {/* <PostNavigation>
          {prev && (
            <Link to={prev.fields.slug}>
              Previous: {prev.frontmatter.title}
            </Link>
          )}

          {next && (
            <Link to={next.fields.slug}>Next: {next.frontmatter.title}</Link>
          )}
        </PostNavigation> */}
        <Link to="/#projects">&lt; Back to Projects</Link>
      </PostBody>
    </Layout>
  );
};

export default BlogPost;
